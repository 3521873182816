.notifications {
  &__container {
    align-items: center;
    display: flex;
    position: relative;
  }
  &__icon {
    cursor: pointer;
    position: absolute;
    &--newNotificationsIcon {
      background: theme('colors.white');
      border: 0.1rem solid theme('colors.white');
      border-radius: 10rem;
      right: 0;
      top: -1.2rem;
      &--forErrors {
        path {
          fill: theme('colors.red');
        }
      }
      &--forWarnings {
        path {
          fill: theme('colors.galliano');
        }
      }
    }
    &--notificationsIcon {
      font-size: 2rem;
      right: 0.4rem;
    }
  }
}
