@import 'styles/_mixins.scss';

.layoutAfterLogin {
  &__container {
    background-color: theme('colors.athensGray2');
    min-height: 100vh;
  }
  &__content {
    max-width: 160rem;
    padding: 2.8rem 0;
    padding-left: 28rem;
    padding-right: 3rem;
    width: 100vw;
    @include stylesForSmartphone() {
      padding: 1.6rem;
      padding-top: 13rem;
    }
  }
}
