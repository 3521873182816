.menu {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 1.2rem;
  }
  &__iconContainer {
    align-items: center;
    display: flex;
    height: 2rem;
    justify-content: center;
    width: 2rem;
  }
  &__item {
    color: theme('colors.fuscousGray');
    display: flex;
    font-size: 1.6rem;
    height: 4rem;
    padding: 0.8rem 0 0.8rem 1.6rem;
    li > span:nth-child(1) {
      color: theme('colors.endeavour');
    }
    li > span:nth-child(2) {
      font-weight: theme('fontWeight.medium');
    }
    &--active {
      background-color: theme('colors.endeavour10');
      border-radius: 0.5rem;
      color: theme('colors.endeavour');
    }
  }
  &__itemContainer {
    align-items: center;
    column-gap: 1.2rem;
    display: flex;
    line-height: 2.4rem;
  }
  &__textContainer {
    height: 2rem;
  }
}
