.form {
  &__button {
    width: 100%;
  }
  &__container {
    margin-top: 4rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
}
