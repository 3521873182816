.loginForm {
  &__container {
    background: theme('colors.white') 0% 0% no-repeat padding-box;
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0.2rem theme('colors.black16');
    max-width: 36rem;
    padding: 3.6rem;
    width: 100%;
  }
  &__logo {
    display: block;
    margin: 0 auto;
    max-width: 12.4rem;
  }
}
