@import 'styles/_mixins.scss';

.timePeriodSelector {
  &__arrow {
    cursor: pointer;
    font-size: 1.6rem;
  }
  &__arrowsContainer {
    align-items: center;
    column-gap: 3.6rem;
    display: flex;
  }
  &__calendarContainer {
    display: flex;
  }
  &__container {
    background-color: theme('colors.white');
    border-bottom: 0.1rem solid theme('colors.mercury');
    padding: 1.6rem 0;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 3rem;
    @include stylesForSmartphone() {
      flex-direction: column-reverse;
      padding: 1.6rem;
      width: 100%;
    }
  }
  &__containerInternal {
    display: flex;
    justify-content: space-between;
    max-width: 129rem;
    @include stylesForSmartphone() {
      flex-direction: column-reverse;
      row-gap: 1.2rem;
      width: 100%;
    }
  }
  &__input {
    background: theme('colors.wildSand');
    border-radius: 0.5rem;
    font-size: 1.2rem;
    padding: 0.8rem;
    &--year {
      padding-bottom: 0.9rem;
      padding-top: 0.9rem;
    }
  }
  &__leftContainer {
    column-gap: 3.2rem;
    display: flex;
    min-height: 3.3rem;
    @include stylesForSmartphone() {
      justify-content: space-between;
    }
  }
  &__period {
    background-color: transparent;
    cursor: pointer;
    font-size: 1.4rem;
    margin: 0.2rem;
    padding: 0.2rem 3.2rem;
    text-transform: uppercase;
    @include stylesForSmartphone() {
      padding: 0.2rem 2.7rem;
      width: 100%;
      text-align: center;
    }
    &--active {
      background-color: theme('colors.white');
      border-radius: 0.7rem;
      box-shadow: 0 0.3rem 0.8rem theme('colors.black16');
    }
    &--disabled {
      cursor: not-allowed;
    }
  }
  &__periodSelector {
    background-color: theme('colors.athensGray');
    border-radius: 0.7rem;
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
    @include stylesForSmartphone() {
      width: 100%;
      flex-wrap: nowrap;
    }
  }
  &__rightContainer {
    align-items: center;
    display: flex;
    @include stylesForSmartphone() {
      margin-bottom: 1.2rem;
    }
  }
}
