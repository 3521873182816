.simpleChart {
  &__bar {
    height: .5rem;
    width: 100%;
    &--placeholder {
      background-color: theme('colors.mercury');
    }
  }
  &__barContainer {
    display: flex;
  }
  &__container {
    width: 100%;
    cursor: pointer;
  }
  &__containerExternal {
    align-items: center;
    column-gap: 1.2rem;
    display: flex;
  }
  &__details {
    color: theme('colors.endeavour');
    font-size: 1.6rem;
    font-weight: theme('fontWeight.medium');

    span {
      font-weight: theme('fontWeight.medium');
    }
  }
  &__goForwardIcon {
    color: theme('colors.fuscousGray50');
    cursor: pointer;
    font-size: 1.6rem;
  }
  &__title {
    color: theme('colors.fuscousGray');
    font-size: 1.2rem;
  }
  &__titleContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
