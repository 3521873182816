@import 'styles/_mixins.scss';

.topBar {
  &__container {
    background-color: theme('colors.endeavour');
    box-shadow: 0 0.1rem 0.8rem theme('colors.black25');
    height: 5rem;
    z-index: 999999999;
    @include stylesForSmartphone() {
      position: fixed;
      top: 6rem;
    }
  }
  &__containerForDropdown {
    column-gap: 2rem;
    display: flex;
  }
  &__containerInternal {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: 160rem;
    padding-left: 28rem;
    padding-right: 3rem;
    width: 100vw;
    @include stylesForSmartphone() {
      justify-content: space-between;
      padding: 0 1.6rem;
    }
  }
  &__dropdown {
    color: theme('colors.white');
    option {
      color: theme('colors.endeavour');
      &:checked {
        font-weight: bold;
      }
    }
  }
  &__ghostElement {
    display: none;
    @include stylesForSmartphone() {
      display: block;
      width: 2.5rem;
    }
  }
  &__icon {
    color: theme('colors.white');
    cursor: pointer;
    font-size: 2.8rem;
    margin-right: 1.6rem;
    &--bars {
      display: none;
      @include stylesForSmartphone() {
        display: block;
      }
    }
  }
  &__pageName {
    color: theme('colors.white');
    font-size: 1.7rem;
    font-weight: theme('fontWeight.bold');
    @include stylesForSmartphone() {
      text-align: center;
    }
  }
}
