* {
  box-sizing: border-box;
}

body {
  line-height: 1.15;
}

html {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
select,
label,
input,
span {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0.02rem;
}
