@import 'styles/_mixins.scss';

.sortingMachine {
  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    @include stylesForSmartphone() {
      flex-direction: column;
    }
    > :nth-child(1) {
      @include stylesForSmartphone() {
        order: 10;
      }
    }
    > :nth-child(2) {
      @include stylesForSmartphone() {
        order: 30;
      }
    }
    > :nth-child(3) {
      @include stylesForSmartphone() {
        order: 20;
      }
    }
  }
  &__icon {
    font-size: 3.6rem;
    @include stylesForSmartphone() {
      margin-bottom: 0.4rem;
    }
    &--green {
      path {
        fill: theme('colors.bilbao');
      }
    }
    &--orange {
      path {
        fill: theme('colors.christine');
      }
    }
    &--red {
      path {
        fill: theme('colors.oldBrick');
      }
    }
  }
  &__leftContainer {
    align-items: center;
    column-gap: 2.4rem;
    display: flex;
  }
  &__separator {
    margin: 2rem 0;
    display: inline-block;
    background-color: theme('colors.mercury');
    height: 1px;
    width: 100%;
    @include stylesForSmartphone() {
      display: none;
    }
  }
  &__text {
    color: theme('colors.fuscousGray');
    font-size: 1.6rem;
    left: 9rem;
    position: absolute;
    @include stylesForSmartphone() {
      left: 0;
      position: relative;
    }
  }
  &__value {
    color: theme('colors.endeavour');
    font-weight: theme('fontWeight.medium');
    font-size: 1.6rem;
  }
}
