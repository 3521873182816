@import 'styles/_mixins.scss';

.useBox {
  &__overviewTitleDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &--key {
      font-size: 12px;
    }
    &--value {
      font-size: 12px;
      font-weight: theme('fontWeight.medium');
    }
    &--valueContainer {
      display: flex;
      column-gap: 4px;
      align-items: center;
    }
  }
}
.useBoxOverview {
  &__separator {
    margin: 1.6rem 0;
  }
  &__simpleChartsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2.8rem;
  }
}
.useBoxOverviewContent {
  &__title {
    color: theme('colors.fuscousGray');
    font-size: 1.2rem;
    text-transform: uppercase;
    &--subtitle {
      font-weight: bold;
    }
  }
}
