@import 'styles/_mixins.scss';

.chartStatistic {
  &__container {
    display: flex;
    flex-direction: column;
    min-width: 10rem;
    padding-right: 1rem;
    // @include stylesForSmartphone() {
    //   min-width: 8rem;
    // }
  }
  &__name {
    color: theme('colors.fuscousGray');
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  &__value {
    font-weight: 600;
    width: 100%;
  }
  &__valueContainer {
    color: theme('colors.endeavour');
    column-gap: 0.8rem;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
  }
}
