@import 'styles/_mixins.scss';

.cardsGrid {
  display: flex;
  flex-wrap: wrap;
  .card {
    width: 100%;
    @include stylesForSmartphone() {
      width: 33%;
    }
  }
}