@import 'styles/_mixins.scss';

.chartStatistics {
  &__container {
    border-bottom: 0.1rem solid theme('colors.mercury');
    display: flex;
    padding: 1.6rem 0;
    @include stylesForSmartphone() {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 1rem 1rem;
    }
    &--onQuantityIndicationsPage {
      padding: 1.6rem;
    }
  }
}
