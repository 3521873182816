.chartTitle {
  &__container {
    align-items: center;
    border-bottom: 0.1rem solid theme('colors.mercury');
    display: flex;
    justify-content: space-between;
    min-height: 5rem;
    padding: .5rem 1.4rem;
  }
  &__title {
    color: theme('colors.endeavour');
    font-size: 1.6rem;
    font-weight: 700;
  }
  &__titleDetailsContainer {
    display: flex;
    justify-content: flex-end;
    min-width: fit-content;
  }
}
