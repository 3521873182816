.useChartBox {
  &__icon {
    path {
      fill: theme('colors.fuscousGray50');
    }
  }
  &__overviewTitleDetails {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    color: theme('colors.fuscousGray');
    &--key {
      font-size: 1.2rem;
    }
    &--value {
      font-size: 1.2rem;
      font-weight: theme('fontWeight.bold');
    }
    &--valueContainer {
      align-items: center;
      column-gap: 0.4rem;
      display: flex;
    }
  }
}
