@-webkit-keyframes starting_flash {
	from { color: theme('colors.bilbao'); }
	to { color: theme('colors.wildSand'); }
}
@-moz-keyframes starting_flash {
	from { color: theme('colors.bilbao'); }
	to { color: theme('colors.wildSand'); }
}
@-ms-keyframes starting_flash {
	from { color: theme('colors.bilbao'); }
	to { color: theme('colors.wildSand'); }
}
@-o-keyframes starting_flash {
	from { color: theme('colors.bilbao'); }
	to { color: theme('colors.wildSand'); }
}
@keyframes starting_flash {
	from { color: theme('colors.bilbao'); }
	to { color: theme('colors.wildSand'); }
}

@-webkit-keyframes stopping_flash {
	from { color: theme('colors.red'); }
	to { color: theme('colors.wildSand'); }
}
@-moz-keyframes stopping_flash {
	from { color: theme('colors.red'); }
	to { color: theme('colors.wildSand'); }
}
@-ms-keyframes stopping_flash {
	from { color: theme('colors.red'); }
	to { color: theme('colors.wildSand'); }
}
@-o-keyframes stopping_flash {
	from { color: theme('colors.red'); }
	to { color: theme('colors.wildSand'); }
}
@keyframes stopping_flash {
	from { color: theme('colors.red'); }
	to { color: theme('colors.wildSand'); }
}

.deviceDetails {
  &__dateTime {
    color: theme('colors.fuscousGray');
    font-size: 1.2rem;
    font-weight: theme('fontWeight.semiBold');
  }
  &__separator {
    border: none;
    border-left: 0.1rem solid theme('colors.fuscousGray50');
    margin: 0 0.8rem;
  }

  &__statusText {
    margin-left: .8rem;
  }

  &__status {
    font-size: 1.2rem;    

    &__unknown {
      color: theme('colors.black');
      svg {
        color: theme('colors.wildSand');
      }
    }

    &__running {
      color: theme('colors.bilbao');
    }

    &__starting {
      color: theme('colors.bilbao');
      svg {
        -webkit-animation: starting_flash 1s infinite alternate;
        -moz-animation: starting_flash 1s infinite alternate;
        -ms-animation: starting_flash 1s infinite alternate;
        -o-animation: starting_flash 1s infinite alternate;
        animation: starting_flash 1s infinite alternate;
      }
    }

    &__stopped {
      color: theme('colors.red');
    }

    &__stopping {
      color: theme('colors.red');
      svg {
        -webkit-animation: stopping_flash 1s infinite alternate;
        -moz-animation: stopping_flash 1s infinite alternate;
        -ms-animation: stopping_flash 1s infinite alternate;
        -o-animation: stopping_flash 1s infinite alternate;
        animation: stopping_flash 1s infinite alternate;
      }
    }
  }
  &__statusContainer {
    padding-left: 0.4rem;
  }
}
