.message {
  &__container {
    background-color: theme('colors.white');
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0.2rem theme('colors.black16');
    padding: 1.6rem;
  }
  &__content {
    color: theme('colors.fuscousGray');
    font-size: 1.6rem;
  }
  &__date {
    color: theme('colors.fuscousGray');
    font-size: 1.6rem;
  }
  &__group {
    &--date {
      column-gap: 0.4rem;
      display: flex;
      align-items: center;
    }
    &--time {
      column-gap: 0.4rem;
      display: flex;
      align-items: center;
    }
    &--1 {
      align-items: center;
      column-gap: 0.8rem;
      display: flex;
    }
    &--2 {
      align-items: center;
      column-gap: 2rem;
      display: flex;
    }
  }
  &__icon {
    height: 1.8rem;
    width: auto;
    margin-right: 0.2rem;
    path {
      fill: theme('colors.fuscousGray50');
    }
  }
  &__id {
    color: theme('colors.fuscousGray');
    font-size: 1.6rem;
  }
  &__line {
    display: flex;
    justify-content: space-between;
    &--1 {
      margin-bottom: 0.8rem;
    }
    &--2 {
      margin-bottom: 1.2rem;
    }
    &--3 {
      margin-top: 1.2rem;
    }
  }
  &__statusIcon {
    height: auto;
    width: 1.6rem;
    &--error {
      path {
        fill: theme('colors.red');
      }
    }
    &--success {
      path {
        fill: theme('colors.bilbao');
      }
    }
    &--warning {
      path {
        fill: theme('colors.galliano');
      }
    }
  }
  &__time {
    color: theme('colors.fuscousGray');
    font-size: 1.6rem;
  }
  &__title {
    color: theme('colors.endeavour');
    font-size: 1.6rem;
    font-weight: bold;
  }
}
