@import 'styles/_mixins.scss';

.dashboard {
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  &__row {
    display: flex;
    filter: drop-shadow(0 0.2rem 0.2rem theme('colors.black16'));
    gap: 2rem;
    justify-content: flex-start;
    @include stylesForSmartphone() {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
}
