.loginPage {
  &__container {
    align-items: center;
    background-color: theme('colors.athensGray');
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100vw;
  }
}
