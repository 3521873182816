@import 'styles/_mixins.scss';

.sidebar {
  background-color: theme('colors.white');
  box-shadow: 0.3rem 0 0.6rem theme('colors.black16');
  display: flex;
  flex-direction: column;
  height: calc(100vh - 5rem - 6.6rem);
  left: 0;
  padding: 2.8rem 0.8rem;
  position: absolute;
  width: 25rem;
  z-index: 9;

  ul:first-of-type {
    margin-bottom: 3rem;
  }

  @include stylesForSmartphone() {
    height: 100vh;
    padding-top: 110px;
    position: fixed;
  }

  @include transitionForAll();
  &--hidden {
    @include stylesForSmartphone() {
      @include transitionForAll();
      left: -30rem;
    }
  }
  &--fixed {
    @include transitionForAll();
    height: 100vh;
    position: fixed;
    top: 0;
  }

  .mobileClose {
    display: none;
    text-transform: uppercase;
    @include stylesForSmartphone() {
      display: flex;
      align-items: center;
    }
    padding: 3rem 1rem 1.4rem;
    border-bottom: 0.1rem solid theme('colors.mercury');
    margin-bottom: 3rem;

    &__icon {
      height: 3rem;
      width: auto;
      path {
        fill: theme('colors.primaryColor');
      }
    }

    &__text {
      color: theme('colors.primaryColor');
      font-size: 1.8rem;
      padding-left: 2rem;
    }
  }
}
