@font-face {
  font-family: 'Eurostile';
  src: url('/assets/fonts/EurostileLTStd.woff') format('woff'),
    url('/assets/fonts/EurostileLTStd.woff2') format('woff2');
  font-weight: 400; // normal
}

@font-face {
  font-family: 'Eurostile';
  src: url('/assets/fonts/EurostileLTStd-Demi.woff') format('woff'),
    url('/assets/fonts/EurostileLTStd-Demi.woff2') format('woff2');
  font-weight: 500; // medium
}

@font-face {
  font-family: 'Eurostile';
  src: url('/assets/fonts/EurostileLTStd-Bold.woff') format('woff'),
    url('/assets/fonts/EurostileLTStd-Bold.woff2') format('woff2');
  font-weight: 700; // bold
}

@font-face {
  font-family: 'NeoTech';
  src: url('/assets/fonts/NeoTech-Bold.woff') format('woff'),
    url('/assets/fonts/NeoTech-Bold.woff2') format('woff2');
  font-weight: 700; // bold
}
