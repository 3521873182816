.icon {
  color: theme('colors.silver');
  &--fi {
    &:before {
      content: '\2CAB';
      display: inline-block;
      font-family: sans-serif;
      transform: rotate(30deg);
    }
  }
  &--sigma {
    &:before {
      content: '\03A3';
      font-family: sans-serif;
    }
  }
  &--times {
    &:before {
      content: '\2A09';
      font-family: sans-serif;
    }
  }
}
