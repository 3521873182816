@import 'styles/_mixins.scss';

.header {
  &__container {
    background-color: theme('colors.white');
    display: flex;
    justify-content: space-between;
    max-width: 160rem;
    padding: 1.2rem 0;
    padding-left: 28rem;
    padding-right: 3rem;
    width: 100vw;
    z-index: 999999999;
    @include stylesForSmartphone() {
      padding: 1.2rem 1.6rem;
      position: fixed;
      top: 0;
    }
  }
  &__containerExternal {
    background-color: theme('colors.white');
  }
  &__containerLeft {
    display: flex;
  }
  &__containerRight {
    align-items: center;
    display: flex;
  }
  &__logo {
    height: 100%;
    width: 4.4rem;
  }
}
