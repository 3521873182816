.dropdown {
  &__select {
    background: transparent;
    border: none;
    color: theme('colors.endeavour');
    cursor: pointer;
    outline: none;
    font-size: 1.6rem;
    font-weight: theme('fontWeight.semiBold');
    width: 100%;
  }
}
