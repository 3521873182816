.input {
  &__input {
    border: none;
    border-bottom: 0.1rem solid theme('colors.endeavour');
    outline: none;
    width: 100%;
    font-size: 1.6rem;
  }
  &__label {
    color: theme('colors.fuscousGray');
    display: block;
    font-size: 1.2rem;
  }
}
