@import 'styles/_mixins.scss';

.box {
  &__container {
    background-color: theme('colors.white');
    border-radius: 0.5rem;
    @include stylesForSmartphone() {
      width: 100% !important;
    }
  }
  &__contentContainer {
    display: block;
    padding: 1.4rem;
    @include stylesForSmartphone() {
      display: block;
      flex-direction: row;
      justify-content: space-around;
      &--SORTING_MACHINES {
        display: flex;
      }
    }
  }
}
